html,
body,
#root {
  height: 100%;
}

.ant-tooltip .ant-tooltip-inner {
  background-color: white;
  color: #2c3848;
}

.ant-tooltip .ant-tooltip-arrow::before {
  border-top-color: white;
  background-color: white;
}
.ant-tooltip .ant-tooltip-arrow-content {
  background-color: white;
}

.ant-modal-wrap.is-expired {
  left: 240px;
}
